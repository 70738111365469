<template>
  <div>
    <date-range-day
      @dayRange="dateRangePicker"
      v-bind="{
        range: range,
        format: format,
        formatted: formatted,
        label: label,
        noShortcuts: noShortcuts,
        value: value,
        required: required
      }"
      v-if="type === 'range-day'"
    />
    <date-range-month
      @monthRange="dateRangePicker"
      :placeholder="placeholder"
      :label="label"
      :minDate="minDate"
      :maxDate="maxDate"
      :value="value"
      :keyAdd="keyAdd"
      :required="required"
      v-if="
        type === 'range-month' || type === 'range-date-for-two-columns' || type === 'month_range'
      "
    />
    <date-month
      @monthRange="dateRangePicker"
      :placeholder="placeholder"
      :label="label"
      :minDate="minDate"
      :maxDate="maxDate"
      :value="value"
      :keyAdd="keyAdd"
      :required="required"
      v-if="type === 'month'"
    />
    <date-range-year
      @yearRange="dateRangePicker"
      :placeholder="placeholder"
      :label="label"
      :minDate="minDate"
      :maxDate="maxDate"
      :value="value"
      :required="required"
      v-if="type === 'range-year'"
    />
  </div>
</template>

<script>
const DateRangeDay = () =>
  import("@/components/Widgets/DateRangePicker/Content/DateRangeDay/DateRangeDay");
const DateRangeMonth = () =>
  import("@/components/Widgets/DateRangePicker/Content/DateRangeMonth/DateRangeMonth");
const DateRangeYear = () =>
  import("@/components/Widgets/DateRangePicker/Content/DateRangeYear/DateRangeYear");
const DateMonth = () => import("@/components/Widgets/DateRangePicker/Content/DateMonth/DateMonth");

export default {
  name: "DateRangePicker",
  mixins: [],
  props: [
    "label",
    "value",
    "tooltip",
    "loading",
    "variant",
    "readonly",
    "placeholder",
    "hidden",
    "role",
    "pk",
    "model",
    "deep",
    "type",
    "range",
    "format",
    "formatted",
    "noShortcuts",
    "minDate",
    "maxDate",
    "keyAdd",
    "defaultValue",
    "required"
  ],
  components: {
    DateMonth,
    DateRangeYear,
    DateRangeDay,
    DateRangeMonth
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    dateRangePicker(value) {
      let data = {
        eventName: "dateRange",
        data: {
          value: value,
          label: this.label,
          items: this.items,
          loading: this.loading,
          pk: this.pk,
          model: this.model,
          role: this.role,
          keyAdd: this.keyAdd,
          type: this.type,
          hidden: this.hidden
        }
      };
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
/deep/ {
  .custom-button {
    height: 29px;
  }
}
</style>
