var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'range-day')?_c('date-range-day',_vm._b({on:{"dayRange":_vm.dateRangePicker}},'date-range-day',{
      range: _vm.range,
      format: _vm.format,
      formatted: _vm.formatted,
      label: _vm.label,
      noShortcuts: _vm.noShortcuts,
      value: _vm.value,
      required: _vm.required
    },false)):_vm._e(),(
      _vm.type === 'range-month' || _vm.type === 'range-date-for-two-columns' || _vm.type === 'month_range'
    )?_c('date-range-month',{attrs:{"placeholder":_vm.placeholder,"label":_vm.label,"minDate":_vm.minDate,"maxDate":_vm.maxDate,"value":_vm.value,"keyAdd":_vm.keyAdd,"required":_vm.required},on:{"monthRange":_vm.dateRangePicker}}):_vm._e(),(_vm.type === 'month')?_c('date-month',{attrs:{"placeholder":_vm.placeholder,"label":_vm.label,"minDate":_vm.minDate,"maxDate":_vm.maxDate,"value":_vm.value,"keyAdd":_vm.keyAdd,"required":_vm.required},on:{"monthRange":_vm.dateRangePicker}}):_vm._e(),(_vm.type === 'range-year')?_c('date-range-year',{attrs:{"placeholder":_vm.placeholder,"label":_vm.label,"minDate":_vm.minDate,"maxDate":_vm.maxDate,"value":_vm.value,"required":_vm.required},on:{"yearRange":_vm.dateRangePicker}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }